<template>
  <div>
    <div ref="editorContainer"
      class="quill-editor" />
  </div>
</template>

<script>
export default {
  name: 'QuillEditor',
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      editor: null
    }
  },
  watch: {
    value(val) {
      if (this.editor && this.editor.root.innerHTML !== val) {
        this.editor.root.innerHTML = val
      }
    }
  },
  mounted() {
    this.initializeEditor()
  },
  beforeDestroy() {
    this.removeDropdownEventListeners()
  },
  methods: {
    addDropdownEventListeners() {
      const toolbar = this.editor.getModule('toolbar')
      const dropdowns = toolbar.container.querySelectorAll('.ql-picker')

      dropdowns.forEach((dropdown) => {
        dropdown.addEventListener('mousedown', this.handleDropdownClick)
      })
    },

    addToolbarHandler(quill) {
      const toolbar = quill.getModule('toolbar')
      toolbar.addHandler('color', function(color){
        const currentFormat = this.getFormat()
        const font = currentFormat.custom_font?.face || 'Arial'
        const size = currentFormat.custom_font?.size || '3'
        if (color) {
          this.format('custom_font', { face: font, color, size })
        } else {
          this.format('custom_font', false)
        }
      }.bind(quill))

      toolbar.addHandler('font', function(font){
        const currentFormat = this.getFormat()
        const color = currentFormat.custom_font?.color || 'black'
        const size = currentFormat.custom_font?.size || '3'

        if (font) {
          this.format('custom_font', { face: font, color, size })
        } else {
          this.format('custom_font', false)
        }
      }.bind(quill))

      toolbar.addHandler('size', function (size) {
        const currentFormat = this.getFormat()
        const font = currentFormat.custom_font?.face || 'Arial'
        const color = currentFormat.custom_font?.color || 'black'

        if (size) {
          this.format('custom_font', { face: font, color, size })
        } else {
          this.format('custom_font', false)
        }
      }.bind(quill))
    },

    addToolbarTooltips() {
      const tooltips = {
        clean: 'Remove formatting'
      }

      const toolbar = this.$refs.editorContainer.previousSibling
      const buttons = toolbar.querySelectorAll('button')

      buttons.forEach((button) => {
        const format = button.className.match(/ql-(\w+)/)?.[1] // Extract format name
        if (tooltips[format]) {
          button.setAttribute('title', tooltips[format]) // Add tooltip
        }
      })
    },

    getToolbarOptions() {
      const toolbarSizes = ['1','2','3','4','5','6','7']
      const toolbarFonts = ['Arial', 'Arial Black', 'Comic Sans MS', 'Courier New', 'Narrow', 'Garamond',
      'Georgia', 'Impact', 'Sans Serif', 'Serif', 'Tahoma', 'Trebuchet MS', 'Verdana']

      const toolbarOptions = [
        [{ 'size': toolbarSizes }],
        [{ 'font': toolbarFonts }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ 'color': [] }, { 'background': [] }],
        [{ 'align': [] }],
        [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
        [{ 'indent': '-1'}, { 'indent': '+1' }],
        ['clean']
      ]
      return toolbarOptions
    },

    initializeEditor() {
      this.registerCustomFontBlot()
      this.registerDivBlot()

      this.registerCustomSizeStyle()
      this.registerCustomFontStyle()

      const toolbarOptions = this.getToolbarOptions()
      this.editor = new Quill(this.$refs.editorContainer, {
        theme: 'snow',
        modules: {
          toolbar: toolbarOptions
        }
      })
      this.addToolbarHandler(this.editor)
      this.addToolbarTooltips()

      const sanitisedHTML = DOMPurify.sanitize(this.value)
      this.editor.clipboard.dangerouslyPasteHTML(sanitisedHTML)
      this.editor.on('text-change', () => {
        this.$emit('input', this.editor.root.innerHTML)
      })

      this.addDropdownEventListeners()
    },

    handleDropdownClick(event) {
      event.preventDefault()
      setTimeout(() => {
        this.editor.focus()
      }, 0)
    },

    registerCustomFontBlot() {
      const Inline = Quill.import('blots/inline')
      class FontBlot extends Inline {
        static create(value) {
          let node = super.create()
          node.setAttribute('face', value.face)
          node.setAttribute('color', value.color)
          node.setAttribute('size', value.size)
          return node
        }

        static formats(node) {
          return {
            face: node.getAttribute('face'),
            color: node.getAttribute('color'),
            size: node.getAttribute('size')
          }
        }
      }
      FontBlot.blotName = 'custom_font'
      FontBlot.tagName = 'font'
      Quill.register(FontBlot, true)
    },

    registerCustomFontStyle() {
      const Font = Quill.import('attributors/style/font')
      Font.whitelist = ['Arial', 'Arial Black', 'Comic Sans MS', 'Courier New', 'Narrow', 'Garamond',
        'Georgia', 'Impact', 'Sans Serif', 'Serif', 'Tahoma', 'Trebuchet MS', 'Verdana']
      Quill.register(Font, true)
    },

    registerCustomSizeStyle() {
      const Size = Quill.import('attributors/style/size')
      Size.whitelist = ['1','2','3','4','5','6','7']
      Quill.register(Size, true)
    },

    registerDivBlot() {
      var Block = Quill.import('blots/block')
      Block.tagName = 'DIV'
      Quill.register(Block, true)
    },

    removeDropdownEventListeners() {
      const toolbar = this.editor.getModule('toolbar')
      const dropdowns = toolbar.container.querySelectorAll('.ql-picker')

      dropdowns.forEach((dropdown) => {
        dropdown.removeEventListener('mousedown', this.handleDropdownClick)
      })
    }
  }
}
</script>

<style lang="scss">
.ql-snow .ql-font.ql-picker {
  width: 140px;
}

.ql-snow .ql-size.ql-picker {
  width: 80px;
}

$fonts: (
  "Arial",
  "Arial Black",
  "Comic Sans MS",
  "Courier New",
  'Narrow',
  'Garamond',
  'Georgia',
  'Impact',
  'Sans Serif',
  'Serif',
  'Tahoma',
  'Trebuchet MS',
  'Verdana'
);

@each $font in $fonts {
  .ql-snow .ql-picker.ql-font .ql-picker-label[data-value="#{$font}"]::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="#{$font}"]::before {
    content: "#{$font}";
  }
}

$sizes: (
  '1', '2', '3', '4', '5', '6', '7'
);

@each $size in $sizes {
  .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="#{$size}"]::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="#{$size}"]::before {
    content: "Size #{$size}";
  }
}
</style>